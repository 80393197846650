import styled from 'styled-components';
import { Card, Row, Typography } from '@pankod/refine-antd';
import { Tabs, Tag } from 'antd';

export const PageWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
`;

export const PaymentMethodInfoWrapper = styled(Card)`
  border-radius: 16px;
  overflow: hidden;

  & .ant-card-body {
    padding: 24px 0 0;
  }
`;

export const OffersTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 12px 24px;
    font-weight: 500;
  }

  .ant-tabs-nav {
    margin-bottom: 24px;
  }
`;

export const PageCommonWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
`;

export const PaymentInfoWrapper = styled.div`
  width: 100%;
`;

export const PaymentInfoCard = styled.div`
  background-color: #ffffff;
  border-radius: 16px 16px 24px 24px;
  padding: 0 24px 24px 24px;
`;
export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  gap: 16px;
`;
export const TypographyTitle = styled(Typography.Title)`
  &&& {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }
`;
export const FieldStyled = styled.span`
  color: #000000a6;
`;
export const GapedRow = styled(Row)`
  gap: 8px;
`;
export const CardWrapper = styled(Row)`
  gap: 32px;
`;
export const ColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const TextPopoverStyled = styled.p`
  max-width: 300px;
  word-break: break-word;
  color: #000000a6;
  background: transparent;
  margin: 0;
`;
export const StyledTag = styled(Tag)`
  height: fit-content;
`;
