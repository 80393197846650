import { useTranslation } from 'react-i18next';
import {
  CheckCircleFilled,
  CloseOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import ClientOutlined from 'shared/components/ui/assets/svg/ClientOutlined.svg';
import React, { useContext, useEffect, useState } from 'react';
import { InfoCard, Title } from '../../../shared/ui-kit';
import {
  ButtonBackStyled,
  CardsContainer,
  ContainerElemsStyled,
  ContainerStyled,
  FooterStyled,
  LoaderStyled,
} from './OfferRequestConfirm.styles';
import { Button, Modal, notification, Typography } from '@pankod/refine-antd';
import { getOfferRequest } from '../api/getOfferRequest';
import { Link, useParams } from '@pankod/refine-react-router-v6';
import { useRequestConfirmState } from '../model/useRequestConfirmState';
import { validateNumericInput } from '../../../shared/helpers/script';
import CustomInputWithClear from './CustomInputWithClear';
import BigNumber from 'bignumber.js';
import { formatSecondsToTime } from '../../../shared/helpers/formatSecondsToTime';
import BottomInfoContainer from './bottom-info/BottomInfoContainer';
import { Flex } from '../../../shared/components/styled';
import { useNavigation } from '@pankod/refine-core';
import {
  confirmOfferRequest,
  ConfirmOfferRequestError,
  EConfirmOfferRequestErrors,
} from '../api/confirmOfferRequest';
import { ExchangePointsContext } from '../../../shared/contexts/exchange-points-context';
import { getExchangePoint } from '../api/getExchangePoint';
import { useUserState } from '../../../shared/state/useUserState';
import { getExternalAccount } from '../api/getExternalAccount';
import ButtonLink from './ButtonLink';
import BackModal from './BackModal';
import { ModalKeys } from '../../../shared/components/ui/RenderModal/model/enums';
import { RenderModal } from '../../../shared/components/ui/RenderModal';
import { useModalState } from '../../../shared/components/ui/RenderModal/model/useModalState';
import dayjs from 'dayjs';
import convertToUTC from '../../../shared/helpers/convertToUTC';
import BN from 'bignumber.js';
import { removeTrailingZeros } from '../../../shared/helpers/removeTrailingZeros';
import { useResize } from 'shared/hooks/useResize';

const OfferRequestConfirmPage = () => {
  const { t } = useTranslation(['offers', 'shared']);
  const { id } = useParams();
  const { push } = useNavigation();

  const [isOpenBackModal, setIsOpenBackModal] = useState(false);

  const { openModal } = useModalState();

  const { activePoint } = useContext(ExchangePointsContext);

  const requestData = useRequestConfirmState((state) => state.requestData);

  const offerRequest = useRequestConfirmState(
    (state) => state.offerRequestStaticData
  );
  const setStaffs = useRequestConfirmState((state) => state.setStaffs);
  const setAccounts = useRequestConfirmState((state) => state.setAccounts);
  const exchangeFreezeSecondsFormat = formatSecondsToTime(
    offerRequest?.offer?.exchangeFreezeSeconds ?? 0
  );
  const resetForm = useRequestConfirmState((state) => state.resetForm);
  const offerRequestData = useRequestConfirmState((state) => state.requestData);

  const changeRequestDataField = useRequestConfirmState(
    (state) => state.changeRequestDataField
  );

  const setRequestDataFromOffer = useRequestConfirmState(
    (state) => state.setRequestDataFromOffer
  );

  const setOfferRequestStaticData = useRequestConfirmState(
    (state) => state.setOfferRequestStaticData
  );

  const validateForm = useRequestConfirmState((state) => state.validateForm);

  const handleChangeCommissionPercent = (value: string) => {
    const validValue = validateNumericInput(value, 2, 4);
    changeRequestDataField('commission_percent', validValue);
  };

  const handleChangeCryptoCommissionAmount = (value: string) => {
    const validValue = validateNumericInput(value, 20, 8);
    changeRequestDataField('crypto_commission_amount', validValue);
  };

  const handleResetCommissionPercent = () => {
    changeRequestDataField('commission_percent', '0');
  };

  const handleResetCryptoCommissionAmount = () => {
    changeRequestDataField('crypto_commission_amount', '0');
  };

  const showSuccessNotification = (
    isBuyingCrypto: boolean,
    accountName: string
  ) => {
    const successNotifObject = {
      icon: <CheckCircleFilled style={{ color: 'rgba(19, 194, 194, 1)' }} />,
      style: { borderLeft: '6px solid rgba(19, 194, 194, 1)' },
    };
    notification.success({
      ...successNotifObject,
      message: isBuyingCrypto
        ? t('Сделка на покупку USDT принята')
        : t('Сделка на продажу USDT принята'),
      description: isBuyingCrypto ? (
        <Typography.Text>
          {`${t('Сделка на покупку')} `}
          <ButtonLink
            offerRequestName={String(offerRequest?.name)}
            offerRequestId={String(offerRequest?.id)}
          />
          {` ${t('USDT успешно принята точка')}`}
        </Typography.Text>
      ) : (
        <Flex align={'flex-start'} vertical>
          <Typography.Text>
            {`${t('Сделка на продажу')} `}
            <ButtonLink
              offerRequestName={String(offerRequest?.name)}
              offerRequestId={String(offerRequest?.id)}
            />
            {` ${t('USDT успешно принята точка')}`}
          </Typography.Text>
          <Typography.Text>
            {t('Пожалуйста запятая убедитесь что к', {
              exchangeDatetime: offerRequestData?.exchange_date
                .startOf('day')
                .add(offerRequestData?.exchange_time.hour() - 2, 'hours')
                .add(offerRequestData?.exchange_time.minute(), 'minutes')
                .format('DD.MM.YYYY HH:mm'),
              walletAddress: accountName,
              cashAmount: `${offerRequest?.cryptoAmount} ${offerRequest?.offer?.cryptoPaymentSystemCurrency?.currencyKey}`,
            })}
          </Typography.Text>
        </Flex>
      ),
    });
  };

  const handleOpenBackModal = () => {
    setIsOpenBackModal(true);
  };

  const handleToBack = () => {
    setIsOpenBackModal(false);
  };

  const handleToRequestList = () => {
    push('/transactions');
  };

  const handleAcceptDeal = async () => {
    if (offerRequest?.offer?.type === 'cash') {
      if (!validateForm()) {
        return;
      }
    }
    if (offerRequestData && offerRequest?.id) {
      try {
        const companyId = useUserState.getState()?.userApi?.company_id;

        let accountName = '';
        if (companyId && !offerRequest?.offer?.isBuyingCrypto) {
          const externalAccount = await getExternalAccount('BTC', companyId);
          if (externalAccount) {
            accountName = externalAccount.name;
          }
        }

        const exchange_datetime = convertToUTC(
          dayjs(offerRequestData.exchange_date)
            .startOf('day')
            .add(offerRequestData.exchange_time.get('hours'), 'hours')
            .add(offerRequestData.exchange_time.get('minutes'), 'minutes')
        );

        const requestData = JSON.parse(JSON.stringify(offerRequestData));
        delete requestData.exchange_date;
        delete requestData.exchange_time;
        await confirmOfferRequest(
          { ...requestData, exchange_datetime },
          offerRequest?.id,
          offerRequest?.offer?.type
        );

        showSuccessNotification(
          !!offerRequest?.offer?.isBuyingCrypto,
          accountName
        );
        push(`/offers/request/${offerRequest?.id}`);
      } catch (error: unknown) {
        if (error instanceof ConfirmOfferRequestError) {
          Modal.warning({
            title: t('Вы не можете принять сделку'),
            centered: true,
            content: (
              <Typography.Text>
                {error.errors.includes(
                  EConfirmOfferRequestErrors.offerMustBeActive
                )
                  ? t('Оффер был отменен или закрыт запятая поэтому')
                  : t('В оффере недостаточно средств запятая чтобы', {
                      cashAmount: `${offerRequest?.cashAmount} ${offerRequest?.offer?.cashCurrencyCode}`,
                    })}
              </Typography.Text>
            ),
          });
        } else {
          notification.error({
            message: t('shared:тексты.Ошибка запроса'),
            description: t(
              'shared:тексты.Произошла ошибка при выполнении запроса точка'
            ),
          });
        }
      }
    }
  };

  const handleOpenCancelModal = () => {
    let modalProps = {
      id: offerRequest?.id,
      name: offerRequest?.name,
      isBuyingCrypto: offerRequest?.offer?.isBuyingCrypto,
      currency: offerRequest?.offer?.cryptoPaymentSystemCurrency?.currencyKey,
    };
    openModal(ModalKeys.ModalCancelTransactionFromConfirm, modalProps);
  };

  const getClientCourseWithCommissionsHandler = () => {
    if (offerRequest?.offer?.isBuyingCrypto) {
      // по покупает
      const temp = BigNumber(Number(offerRequest?.offer?.exchangeRate))
        .multipliedBy(Number(requestData?.commission_percent))
        .div(100)
        .toFixed(2);
      return removeTrailingZeros(
        BigNumber(Number(offerRequest?.offer?.exchangeRate))
          .minus(temp)
          .toFixed(2)
      );
    } else {
      // по продает
      const temp = BigNumber(Number(offerRequest?.offer?.exchangeRate))
        .multipliedBy(Number(requestData?.commission_percent))
        .div(100)
        .toFixed(2);
      return removeTrailingZeros(
        BigNumber(Number(offerRequest?.offer?.exchangeRate))
          .plus(temp)
          .toFixed(2)
      );
    }
  };

  const getCashHandler = () => {
    let tempCashAmount: string;
    if (offerRequest?.offer?.isBuyingCrypto) {
      // ПО покупает
      const temp = BN(offerRequest?.cryptoAmount).minus(
        BN(offerRequest?.cryptoAmount).multipliedBy(
          BN(offerRequest?.offer.initialCommissionPercent).div(100)
        )
      );
      tempCashAmount = BN(temp)
        .minus(Number(requestData?.crypto_commission_amount))
        .multipliedBy(Number(getClientCourseWithCommissionsHandler()))
        .toFixed(2);
      return removeTrailingZeros(tempCashAmount);
    }
    if (!offerRequest?.offer?.isBuyingCrypto) {
      // ПО продает
      const temp = BN(Number(offerRequest?.cryptoAmount)).multipliedBy(
        BN(100).div(100 - Number(offerRequest?.offer?.initialCommissionPercent))
      );
      const temp2 = BN(temp).plus(
        Number(requestData?.crypto_commission_amount)
      );
      tempCashAmount = BN(temp2)
        .multipliedBy(Number(getClientCourseWithCommissionsHandler()))
        .toFixed(2);
      return removeTrailingZeros(tempCashAmount);
    }
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const data = await getOfferRequest(id);
        setOfferRequestStaticData(data);
        setRequestDataFromOffer(data);
      })();
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      if (activePoint && offerRequest) {
        const exchangePoint = await getExchangePoint(activePoint.id);

        if (exchangePoint) {
          setStaffs(exchangePoint.user_accounts);
          setAccounts(
            exchangePoint.accounts.filter(
              (account) =>
                account?.currency?.shortname ===
                offerRequest?.offer?.cashCurrencyCode
            )
          );
        }
      }
    })();
  }, [activePoint?.id, offerRequest?.id]);

  useEffect(() => {
    return () => resetForm();
  }, []);

  if (!offerRequest) {
    return <LoaderStyled />;
  }
  return (
    <ContainerStyled>
      <ContainerElemsStyled>
        <Title
          button={{
            callback: handleOpenCancelModal,
            title: t('shared:кнопки.Отклонить'),
            icon: <CloseOutlined style={{ color: '#FF4D4F' }} />,
          }}
          breadcrumbs={[
            {
              title: <Link to="/offers">{t('Офферы и заявки')}</Link>,
            },
            {
              title: t('Подтверждение сделки'),
            },
          ]}
          description={offerRequest?.name}
        >
          {t('Подтверждение сделки на', {
            type: offerRequest?.offer?.isBuyingCrypto
              ? t('shared:тексты.покупку')
              : t('shared:тексты.продажу'),
            currency:
              offerRequest?.offer?.cryptoPaymentSystemCurrency?.currencyKey ||
              'USDT',
          })}
          {offerRequest?.offer?.cashCurrencyCode && (
            <>
              {t('shared:тексты.за')} {offerRequest.offer.cashCurrencyCode}
            </>
          )}
        </Title>
        <CardsContainer>
          <InfoCard
            fields={[
              {
                name: t('shared:тексты.Актив'),
                value:
                  removeTrailingZeros(offerRequest?.cryptoExchangePointAmount) +
                  ' ' +
                  offerRequest?.offer?.cryptoPaymentSystemCurrency?.currencyKey,
              },
              {
                name: t('shared:тексты.Пункт обмена'),
                link: '/points/' + offerRequest?.offer?.exchangePoint?.id,
                value: offerRequest?.offer?.exchangePoint?.name,
              },
              {
                name: t('shared:тексты.Фикс точка стоимость'),
                value: (
                  <CustomInputWithClear
                    value={offerRequestData?.crypto_commission_amount}
                    onChange={handleChangeCryptoCommissionAmount}
                    onReset={handleResetCryptoCommissionAmount}
                    disabled={!offerRequest?.offer?.cryptoCommissionAmount}
                    suffix={
                      offerRequest?.offer?.cryptoPaymentSystemCurrency
                        ?.currencyKey || 'USDT'
                    }
                  />
                ),
              },
              {
                name: t('shared:тексты.Комиссия пункта'),
                value: (
                  <CustomInputWithClear
                    value={offerRequestData?.commission_percent}
                    onChange={handleChangeCommissionPercent}
                    onReset={handleResetCommissionPercent}
                    disabled={!offerRequest?.offer?.cryptoCommissionAmount}
                    suffix={'%'}
                  />
                ),
              },
            ]}
            title={{
              name: offerRequest?.offer?.isBuyingCrypto
                ? t('shared:тексты.Пункт обмена получает')
                : t('shared:тексты.Пункт обмена отдает'),
              icon: <EnvironmentOutlined />,
            }}
            bordered={true}
          />
          <InfoCard
            fields={[
              {
                name: t('shared:тексты.Фиат'),
                value:
                  getCashHandler() +
                  ' ' +
                  offerRequest?.offer?.cashCurrencyCode,
              },
              {
                name: t('shared:тексты.Клиент'),
                link: '/points/1',
                value: offerRequest?.nickname,
              },
              {
                name: t('shared:тексты.Способ оплаты'),
                value:
                  offerRequest?.offer?.type === 'cash' ? (
                    t('shared:тексты.Наличные')
                  ) : (
                    <Link
                      target={'_blank'}
                      to={`payment-methods/${offerRequest?.requisite?.id}`}
                    >
                      {offerRequest?.requisite?.currency?.name}
                    </Link>
                  ),
              },
              {
                name: t('shared:тексты.Курс клиента'),
                value:
                  getClientCourseWithCommissionsHandler() +
                  ' ' +
                  offerRequest?.offer?.cashCurrencyCode,
              },
            ]}
            title={{
              name: offerRequest?.offer?.isBuyingCrypto
                ? t('shared:тексты.Клиент получает')
                : t('shared:тексты.Клиент отдает'),
              icon: <img alt={'client'} src={ClientOutlined} />,
            }}
            bordered={true}
          />
          <InfoCard
            fields={[
              {
                name: t('shared:тексты.Оффер'),
                link: '/offers/' + offerRequest?.offer?.id,
                value: offerRequest?.offer?.name,
              },
              {
                name: t('shared:тексты.Источник курса'),
                value:
                  offerRequest?.offer?.exchangeRateSource === 'manual'
                    ? t('shared:тексты.Не указан')
                    : 'Bitcoin average',
              },
              {
                name: t('shared:тексты.Срок заморозки курса'),
                value:
                  offerRequest?.offer?.type === 'cash' ? (
                    <>
                      {exchangeFreezeSecondsFormat.dDisplay +
                        t('shared:тексты.дней двоеточие') +
                        exchangeFreezeSecondsFormat.hDisplay +
                        t('shared:тексты.часов двоеточие') +
                        exchangeFreezeSecondsFormat.mDisplay +
                        t('shared:тексты.минут сокращенное')}
                    </>
                  ) : (
                    <>1{t('shared:тексты.ч')}</>
                  ),
              },
              {
                name: t('shared:тексты.Курс без комиссии'),
                value:
                  BigNumber(offerRequest?.exchangeRate || 0).toFixed(2) +
                  ' ' +
                  (offerRequest?.offer?.cashCurrencyCode ?? ''),
              },
            ]}
            title={{
              name: t('shared:тексты.Общая информация'),
              icon: <InfoCircleOutlined />,
            }}
            bordered={true}
          />
        </CardsContainer>
        <BottomInfoContainer
          offerType={offerRequest.offer?.type ?? 'cash'}
          isBuyingCrypto={offerRequest?.offer?.isBuyingCrypto ?? false}
        />
      </ContainerElemsStyled>
      <FooterStyled>
        <ButtonBackStyled onClick={handleOpenBackModal} type={'default'}>
          {t('shared:кнопки.Назад')}
        </ButtonBackStyled>
        <Button onClick={handleAcceptDeal} type={'primary'}>
          {t('shared:кнопки.Принять сделку')}
        </Button>
      </FooterStyled>
      <BackModal
        isOpen={isOpenBackModal}
        onOk={handleToRequestList}
        onCancel={handleToBack}
      />
      <RenderModal
        currentModalKey={ModalKeys.ModalCancelTransactionFromConfirm}
      />
    </ContainerStyled>
  );
};
export default OfferRequestConfirmPage;
