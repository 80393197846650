import {
  RequestInfoHeader,
  RequestInfoWrapper,
  Title,
} from './RequestInfo.styles';
import { Typography } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../../../shared/components/styled';
import { Tag } from 'antd';
import Timer from './Timer';
import { IRequest } from '../../model/types';
import RequestInfoItem from './RequestInfoItem';
import { EOfferRequestStatusTagColors } from '../../model/enums';
import { EOfferRequestStatuses } from '../../../../shared/enums';
import dayjs from 'dayjs';
import ActionsBtnBlock from './ActionsBtnBlock';
import { ModalKeys } from '../../../../shared/components/ui/RenderModal/model/enums';
import { useModalState } from '../../../../shared/components/ui/RenderModal/model/useModalState';
import { RenderModal } from '../../../../shared/components/ui/RenderModal';
import { Link, useNavigate } from '@pankod/refine-react-router-v6';
import { DEAL_STATUS } from 'shared/constants';
import useFormattedExchangeRequest from 'pages/offer-request/model/hooks/useFormattedExchangeRequest';
import { useFormattedClientRequest } from 'pages/offer-request/model/hooks/useFormattedClientRequest';

type RequestStatus =
  | 'pending'
  | 'accepted'
  | 'cancelled'
  | 'warranty_collateral'
  | 'ready'
  | 'expired'
  | 'waiting_for_fiat_payment'
  | 'on_argument'
  | 'completed';

interface IProps {
  request: IRequest;
  refetchRequest: () => Promise<void>;
}

const RequestInfo = ({ request, refetchRequest }: IProps) => {
  const { t, i18n } = useTranslation('shared');
  const navigate = useNavigate();
  const { openModal } = useModalState();

  const handleRedirectToConfirm = (id: number) => {
    navigate(`/offers/confirm-request/${id}`);
  };

  const actionButtonClickHandler = (trigger: string) => {
    const modalProps: {
      id: number;
      isBuyingCrypto: boolean;
      name?: string;
      status: string;
      currency: string;
      refetchData: () => Promise<void>;
      deal?: IRequest;
    } = {
      id: request.id,
      isBuyingCrypto: Boolean(request.offer?.is_buying_crypto),
      name: request?.name,
      status: request.status,
      currency: 'USDT',
      refetchData: refetchRequest,
    };

    switch (trigger) {
      case DEAL_STATUS.ready: {
        modalProps.deal = request;
        openModal(ModalKeys.ModalConfirmPaymentSent, modalProps);
        break;
      }
      case DEAL_STATUS.completed: {
        openModal(ModalKeys.ModalConfirmReceiptOfCash, modalProps);
        break;
      }
      case DEAL_STATUS.expired:
      case DEAL_STATUS.cancelled: {
        modalProps.status = request.status;
        openModal(ModalKeys.ModalCancelTransaction, modalProps);
        break;
      }
      case DEAL_STATUS.onArgument: {
        modalProps.currency = 'USDT';
        openModal(ModalKeys.ModalOpenDispute, modalProps);
        break;
      }
      case DEAL_STATUS.accepted: {
        handleRedirectToConfirm(request.id);
        break;
      }
    }
  };

  const exchangePointBlock = useFormattedExchangeRequest(request);
  const clientBlock = useFormattedClientRequest(request);

  return (
    <RequestInfoWrapper>
      <RequestInfoHeader>
        <Flex align={'flex-start'}>
          <Flex gap={4} justify={'flex-start'} align={'flex-start'} vertical>
            <Flex gap={16} align={'flex-start'} vertical>
              <Title level={3}>
                {request.offer?.is_buying_crypto
                  ? t('shared:тексты.Сделка на покупку n за m', {
                      currency:
                        request.offer?.crypto_payment_system_currency
                          ?.currency_key,
                      cashCurrency: request.offer?.cash_currency_code,
                    })
                  : t('shared:тексты.Сделка на продажу n за m', {
                      currency:
                        request.offer?.crypto_payment_system_currency
                          ?.currency_key,
                      cashCurrency: request.offer?.cash_currency_code,
                    })}
              </Title>
              <Tag color={EOfferRequestStatusTagColors[request.status]}>
                {t(`shared:статусы.${EOfferRequestStatuses[request.status]}`)}
              </Tag>
            </Flex>
          </Flex>
          <ActionsBtnBlock
            transitionStatuses={request.allowed_statuses}
            callBack={actionButtonClickHandler}
            dealCurrentStatus={request.status}
            isBuyCrypto={request.offer.is_buying_crypto}
          />
        </Flex>
      </RequestInfoHeader>
      <Flex align={'flex-start'}>
        <RequestInfoItem
          title={
            request.offer?.is_buying_crypto
              ? t('shared:тексты.Пункт получает')
              : t('shared:тексты.Пункт отдает')
          }
          value={String(
            exchangePointBlock.items.find((item) => item.name === 'cash')!.value
          )}
          strong
        />
        <RequestInfoItem
          title={
            request.offer?.is_buying_crypto
              ? t('shared:тексты.Клиент получает')
              : t('shared:тексты.Клиент отдает')
          }
          value={clientBlock.items.find((item) => item.name === 'cash')!.value}
          strong
        />
        <RequestInfoItem
          title={t('shared:тексты.Способ оплаты')}
          value={
            request.offer.type === 'cash' ? (
              t('shared:тексты.Наличные')
            ) : (
              <Link to={`/payment-methods/${request?.requisite?.id}`}>
                {request?.requisite?.currency.name}
              </Link>
            )
          }
        />

        {request.status !== 'on_argument' && request.status !== 'pending' && (
          <Timer deadline={dayjs(request.exchange_datetime).toString()} />
        )}
      </Flex>
      {request.status !== 'pending' && request.offer.type === 'cash' && (
        <Flex>
          <Flex align={'flex-start'} vertical>
            <Typography.Text type="secondary">
              {t('shared:тексты.Место встречи')}
            </Typography.Text>
            <Typography.Text strong>
              {request.exchange_address ?? '-'}
            </Typography.Text>
          </Flex>
        </Flex>
      )}
      <RenderModal currentModalKey={ModalKeys.ModalConfirmReceiptOfCash} />
      <RenderModal currentModalKey={ModalKeys.ModalConfirmPaymentSent} />
      <RenderModal currentModalKey={ModalKeys.ModalOpenDispute} />
      <RenderModal currentModalKey={ModalKeys.ModalCancelTransaction} />
    </RequestInfoWrapper>
  );
};

export default RequestInfo;
