import { axios } from '../../../shared/exios';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { IOfferRequest, IOfferResponse } from '../model/types';
function snakeToCamel(s: string): string {
  return s.replace(/(_\w)/g, (match) => match[1].toUpperCase());
}
function toCamelCaseObj(obj: any): IOfferRequest {
  const newObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!!obj[key] && typeof obj[key as keyof IOfferResponse] === 'object') {
        const camelKey = snakeToCamel(key);
        newObj[camelKey] = toCamelCaseObj(obj[key]);
      } else {
        const camelKey = snakeToCamel(key);
        newObj[camelKey] = obj[key as keyof IOfferResponse]; // Здесь мы не углубляемся внутрь значений
      }
    }
  }
  return newObj;
}
export const getOfferRequest = async (id: string): Promise<IOfferRequest> => {
  try {
    const { data } = await axios.get<{ data: IOfferResponse }>(
      `${API_URL}/exchange-point-offer-requests/` + id,
      {
        params: {
          include:
            'offer,offer.exchange_point,cash_user,offer.cash_payment_system_currency,offer.crypto_payment_system_currency,requisite.currency',
        },
      }
    );
    const normalized = toCamelCaseObj(data.data);
    return normalized;
  } catch (e) {
    console.log(e);
    return {} as IOfferRequest;
  }
};
