import { UserSwitchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { IInfoList } from '../types';
import BN from 'bignumber.js';
import { removeTrailingZeros } from '../../../../shared/helpers/removeTrailingZeros';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { EExchangeRateSource } from 'shared/enums';
import { IRequest } from '../types';
import countryList from 'react-select-country-list';

type RequestStatus =
  | 'pending'
  | 'accepted'
  | 'cancelled'
  | 'warranty_collateral'
  | 'ready'
  | 'expired'
  | 'on_argument'
  | 'completed';

export const useFormattedClientRequest = (request: IRequest): IInfoList => {
  const { t, i18n } = useTranslation('shared');

  let tempCashAmount: string;
  let tempCryptoAmount: string;

  if (!request.offer.is_buying_crypto) {
    // ПО продает актив
    const temp = BN(request.crypto_amount).multipliedBy(
      BN(100).div(100 - Number(request.offer.initial_commission_percent))
    );
    const temp2 = BN(temp).plus(request.crypto_commission_amount);
    tempCashAmount = BN(temp2).multipliedBy(request.client_rate).toFixed(8);
    const temp3 = BN(tempCashAmount)
      .div(request.client_rate)
      .minus(request.crypto_commission_amount);
    tempCryptoAmount = temp3.toFixed(8);
  }

  if (request.offer.is_buying_crypto) {
    // ПО покупает актив
    const temp = BN(request.crypto_amount).minus(
      BN(request.crypto_amount).multipliedBy(
        BN(request.offer.initial_commission_percent).div(100)
      )
    );
    tempCashAmount = BN(temp)
      .minus(request.crypto_commission_amount)
      .multipliedBy(request.client_rate)
      .toFixed(2);
    const temp2 = BN(tempCashAmount)
      .div(request.client_rate)
      .plus(request.crypto_commission_amount);
    tempCryptoAmount = temp2.toFixed(8);
  }

  return useMemo(() => {
    const getCountry = (countryCode: string) =>
      countryList().data.find((country) => country.value === countryCode)
        ?.label;

    const initInfo: IInfoList = {
      items: [
        {
          name: 'cash',
          value: `${removeTrailingZeros(tempCashAmount)} ${
            request.offer?.cash_currency_code
          }`,
        },
        {
          label: t('shared:тексты.Клиент') + ':',
          pos: 1,
          value: `${!!request.client ? request.client.name : request.nickname}`,
          link: `${!!request.client ? `/clients/${request.client.id}` : ''}`,
        },
        {
          label: t('shared:тексты.Курс клиента двоеточие'),
          pos: 2,
          value: `${removeTrailingZeros(request.client_rate)} ${
            request.offer?.cash_currency_code
          }`,
        },
        {
          label: t('shared:тексты.Дата обмена двоеточие'),
          pos: 5,
          value: dayjs(request.exchange_datetime).format('DD.MM.YYYY HH:mm'),
        },
      ],
    };

    if (request.offer.type === 'emoney') {
      initInfo.items.push({
        label: t('shared:тексты.Место обмена двоеточие'),
        pos: 6,
        value:
          getCountry(request.offer.country_code) || request.offer.country_code,
      });
      if (request?.requisites)
        initInfo.items.push({
          label: t('shared:тексты.Реквизиты') + ':',
          pos: 3,
          value: request?.requisites,
        });
      if (request?.full_name) {
        initInfo.items.push({
          label: t('shared:тексты.ФИО отправителя двоеточие'),
          pos: 4,
          value: request?.full_name,
        });
      }
    }

    return initInfo;
  }, [request, i18n.language]);
};
