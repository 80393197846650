import {
  LabelCommentStyled,
  LabelPaymentMethodRowStyled, LinkPointStyled, NoWrapBlockStyled,
  OfferBodyStyled,
  OfferColumnStyled,
  OfferCommentRowStyled,
  OfferRowStyled,
  PaymentMethodRowStyled,
  ValueTextStyled,
  WrapperTabStyled,
} from '../../Offer.styles';
import { Link, useNavigate, useParams } from '@pankod/refine-react-router-v6';
import { CountryFlag } from '../../../../../shared/components/CountryFlag';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IOfferInfoResponseNormalize } from '../../../model/types';
import { useOfferInfoState } from '../../../model/useOfferInfoState';
import { getOfferInfo } from '../../../api/getOfferInfo';
import { Flex } from 'shared/components/styled';
import countryList from 'react-select-country-list';
import { ConfigProvider, Tooltip } from '@pankod/refine-antd';

export const DetailsInfoTab = () => {
  const { t } = useTranslation(['shared']);
  let { id } = useParams();
  const [offerInfo, setOfferInfo] = useState<IOfferInfoResponseNormalize>();
  const [comment, setComment] = useState<string | null>();
  const navigate = useNavigate();
  const { setIsBuyingCrypto, setOfferStatus } = useOfferInfoState();

  useEffect(() => {
    id &&
      getOfferInfo(id).then((data) => {
        if (data) {
          return setOfferInfo(data);
        }
      });
  }, []);

  useEffect(() => {
    if (offerInfo?.id) {
      setIsBuyingCrypto(offerInfo?.isBuyingCrypto);
      setOfferStatus(offerInfo?.status);
      setComment(offerInfo?.comment);
    }
  }, [offerInfo?.id]);

  const getCountry = (countryCode: string) =>
    countryList().data.find((country) => country.value === countryCode)?.label;

  return (
    <WrapperTabStyled>
      <OfferBodyStyled>
        <OfferColumnStyled>
          <OfferRowStyled>
            <NoWrapBlockStyled>{t('shared:тексты.Пункт обмена двоеточие')}</NoWrapBlockStyled>
            <ConfigProvider theme={{
              components: {
                Tooltip: {
                  colorTextLightSolid: '#000000A6',
                  fontSize: 12,
                  controlHeight: 20,
                  paddingSM: 24,
                }
              }
            }}>
            <Tooltip
              color="#E5F9FF"
              title={offerInfo?.exchangePoint?.name} trigger="hover">
            <LinkPointStyled
              to={`/points/${offerInfo?.exchangePoint?.id}`}
            >
              {offerInfo?.exchangePoint?.name}
            </LinkPointStyled>
            </Tooltip>
            </ConfigProvider>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Место обмена двоеточие')}</div>
            {offerInfo?.countryCode && (
              <>
                <CountryFlag
                  width={20}
                  height={14}
                  countryCode={offerInfo?.countryCode}
                />
                <ValueTextStyled>
                  {offerInfo.type === 'cash'
                    ? offerInfo?.city
                    : getCountry(offerInfo?.countryCode)}
                </ValueTextStyled>
              </>
            )}
          </OfferRowStyled>
          <OfferRowStyled>
            <div>
              {t('shared:тексты.Фикс точка стоимость сделки двоеточие')}
            </div>
            <ValueTextStyled>
              {offerInfo?.cryptoCommissionAmount
                ? offerInfo?.cryptoCommissionAmount +
                  ' ' +
                  offerInfo?.cryptoPaymentSystemCurrency?.currencyKey
                : ' - '}
            </ValueTextStyled>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Сумма сделки двоеточие')}</div>
            <ValueTextStyled>
              {t('shared:тексты.от')}
              {offerInfo?.cashMinAmount}
              {t('shared:тексты.до')}
              {offerInfo?.cashMaxAmount}{' '}
              {offerInfo?.cashPaymentSystemCurrency?.currencyKey}
            </ValueTextStyled>
          </OfferRowStyled>
        </OfferColumnStyled>
        <OfferColumnStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Источник курса двоеточие')}</div>
            <ValueTextStyled>
              {offerInfo?.exchangeRateSource === 'manual'
                ? '-'
                : 'Bitcoin average'}
            </ValueTextStyled>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Курс без комиссии двоеточие')}</div>
            <ValueTextStyled>
              {offerInfo?.exchangeRate}{' '}
              {offerInfo?.cashPaymentSystemCurrency?.currencyKey}
            </ValueTextStyled>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Комиссия пункта обмена двоеточие')}</div>
            <ValueTextStyled>
              {offerInfo?.commissionPercent
                ? offerInfo?.commissionPercent + '%'
                : ' - '}
            </ValueTextStyled>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Комиссия Edenex двоеточие')}</div>
            <ValueTextStyled>
              {offerInfo?.initialCommissionPercent}%
            </ValueTextStyled>
          </OfferRowStyled>
        </OfferColumnStyled>
        <OfferColumnStyled>
          {offerInfo?.createdAt && (
            <OfferRowStyled>
              <div>{t('shared:тексты.Дата создания двоеточие')}</div>
              <ValueTextStyled>
                {dayjs(offerInfo?.createdAt).format('DD.MM.YYYY 00:00')}
              </ValueTextStyled>
            </OfferRowStyled>
          )}
          {offerInfo?.dateDeclined && (
            <OfferRowStyled>
              {offerInfo?.status === 'declined' && (
                <div>{t('shared:тексты.Дата отмены двоеточие')}</div>
              )}
              <ValueTextStyled>
                {dayjs(offerInfo?.dateDeclined).format('DD.MM.YYYY 00:00')}
              </ValueTextStyled>
            </OfferRowStyled>
          )}
          {offerInfo?.dateDone && (
            <OfferRowStyled>
              {offerInfo?.status === 'done' && (
                <div>{t('shared:тексты.Дата закрытия двоеточие')}</div>
              )}
              <ValueTextStyled>
                {dayjs(offerInfo?.dateDone).format('DD.MM.YYYY 00:00')}
              </ValueTextStyled>
            </OfferRowStyled>
          )}
          <Flex vertical align="start">
            <LabelPaymentMethodRowStyled>
              {t('shared:тексты.Способ оплаты')}:
            </LabelPaymentMethodRowStyled>
            <ValueTextStyled>
              {offerInfo?.type === 'cash'
                ? t('shared:тексты.Наличные')
                : offerInfo?.requisites?.length
                ? offerInfo?.requisites?.map((el, index) => (
                    <Link to={`/payment-methods/${el.id}`}>
                      {el.currency.name}
                      {offerInfo?.requisites?.length === index + 1 ? ' ' : ', '}
                    </Link>
                  ))
                : '-'}
            </ValueTextStyled>
          </Flex>
        </OfferColumnStyled>
      </OfferBodyStyled>
      <OfferCommentRowStyled>
        <LabelCommentStyled>
          {t('shared:тексты.Комментарий двоеточие')}
        </LabelCommentStyled>
        <div>{ comment ? comment : '-'  }</div>
      </OfferCommentRowStyled>
    </WrapperTabStyled>
  );
};
