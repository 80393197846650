import { Row } from '@pankod/refine-antd';
import { BreadcrumbsPaymentMethodPage } from './BreadcrumbsPaymentPage';
import {
  PageCommonWrapperStyled,
  PageWrapper,
  PaymentMethodInfoWrapper,
  OffersTabs,
  PaymentInfoCard,
  TitleContainer,
  TypographyTitle,
  FieldStyled,
  GapedRow,
  ColumnStyled,
  CardWrapper,
  StyledTag,
} from './PaymentMethodPage.styles';
import { useTranslation } from 'react-i18next';
import { useParams } from '@pankod/refine-react-router-v6';
import { usePaymentRequisites } from '../../../shared/hooks/usePaymentRequisites';
import React from 'react';
import { TruncatedText } from './TruncatedText';
import { FullScreenLoader } from '../../../shared/components/full-screen-loader';

export const PaymentMethodPage = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams();
  const { requisite, refetch, loading } = usePaymentRequisites({
    filters: [{ operator: '=', field: 'id', value: id }],
  });
  const InfoSets = {
    requisites: {
      card_number: t('shared:тексты.Номер реквизитов'),
      phone_number: t('shared:тексты.Номер телефона'),
      account: t('shared:тексты.Номер счёта'),
      null: t('shared:тексты.Номер реквизитов'),
    },
    payment: {
      card_number: t('shared:типы.Карта'),
      phone_number: t('shared:типы.Баланс телефона'),
      account: t('shared:типы.Банк'),
      null: t('shared:типы.Электронный кошелёк'),
    },
  };
  const createInfoGetter =
    <T extends Record<string, string>>(map: T) =>
    (type: keyof T): string =>
      map[type] || t('shared:тексты.Неизвестный тип');

  const paymentInfo = createInfoGetter(InfoSets.payment);
  const requisitesInfo = createInfoGetter(InfoSets.requisites);
  return (
    <PageCommonWrapperStyled>
      {loading ? (
        <FullScreenLoader />
      ) : (
        <PageWrapper>
          <BreadcrumbsPaymentMethodPage name={requisite?.currency?.name} />
          <PaymentMethodInfoWrapper>
            <PaymentInfoCard>
              <TitleContainer>
                <TypographyTitle>{requisite?.currency?.name}</TypographyTitle>
                <StyledTag>{paymentInfo(requisite?.type)}</StyledTag>
              </TitleContainer>
              <CardWrapper justify={'space-between'}>
                <ColumnStyled>
                  <GapedRow>
                    <FieldStyled>{t('shared:тексты.Валюта')}:</FieldStyled>
                    <span>{requisite?.currency?.code}</span>
                  </GapedRow>
                  <GapedRow>
                    <FieldStyled>
                      {requisitesInfo(requisite?.type)}:
                    </FieldStyled>
                    <TruncatedText
                      popoverText={requisite?.requisites}
                      canOpen={false}
                      text={requisite?.requisites || ''}
                      maxCommentLength={20}
                    />
                  </GapedRow>
                </ColumnStyled>
                <ColumnStyled>
                  <FieldStyled>
                    {t('shared:тексты.Комментарий для сотрудников')}:
                  </FieldStyled>
                  <TruncatedText
                    text={requisite?.internal_comment || ''}
                    maxCommentLength={60}
                  />
                </ColumnStyled>
                <ColumnStyled>
                  <FieldStyled>
                    {t('shared:тексты.Комментарий для клиентов')}:
                  </FieldStyled>
                  <TruncatedText
                    text={requisite?.comment || ''}
                    maxCommentLength={60}
                  />
                </ColumnStyled>
              </CardWrapper>
            </PaymentInfoCard>
            <Row gutter={[24, 24]}></Row>
            <OffersTabs />
          </PaymentMethodInfoWrapper>
        </PageWrapper>
      )}
    </PageCommonWrapperStyled>
  );
};
